import { Helmet } from 'react-helmet'

import * as React from 'react'
import Layout from '../../components/Layout'

import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import FAQs from '../../components/FAQs'

import { Box, Flex } from 'grid-styled'
import {
  HomeHeader,
  FormContainer,
  AlternatingContent,
  AboutContact,
  VideoContainer,
  info,
} from '..'
import Form from '../../components/Form'
import {
  Text,
  GallerySection,
  Content,
  MainContent,
} from '../../components/styles'
import Button from '../../components/Button'
import ContentCard from '../../components/ContentCard'
import Hero from '../../components/Hero'
import { Video } from '../../components/procedures'
import GatsbyImage from 'gatsby-image'
import CTA from '../../components/CTA'

class Comp extends React.Component {
  state = {
    loading: true,
  }

  componentDidMount() {
    const script = document.createElement('script')
    script.src = 'https://m5.connective.com.au/clientportal/applyNowEmbedder.js'
    document.body.appendChild(script)

    setTimeout(() => {
      this.setState({ loading: false })
    }, 8000)
  }

  render() {
    return (
      <Layout header="dark">
        <Helmet>
          <title>Mortgage Advice - Parinity Financial Professionals</title>
          <meta
            name="description"
            content="Our mortgage broking advice is comprehensive and can assist you with
            all your financial needs. Contact us about our mortgage advice today!"
          />
        </Helmet>

        <MainContent style={{ marginTop: 50 }}>
          <Flex justifyContent="center" id="apply">
            <h2>Submit your details to Parinity</h2>
          </Flex>
          <div id="apply-now-main" style={{ marginTop: 50 }}>
            <input id="apply-now-companyId" type="hidden" value="P201414" />

            <input id="apply-now-caAgent" type="hidden" value="CA58701" />

            <input
              id="apply-now-token"
              type="hidden"
              value="0f92c287-2273-4d0e-937d-8e86eaa68c3b"
            />

            <input
              id="apply-now-key"
              type="hidden"
              value="Aqitry8BfHiGMNbelo2oao6XA6vQ5IN1JhVqNtEf"
            />

            <div id="apply-now-content" />

            {this.state.loading && <p>Loading...</p>}
            <script src="https://m5.connective.com.au/clientportal/applyNowEmbedder.js" />
          </div>
        </MainContent>

        <CTA
          title="Download our wealth tracking app"
          subtitle="A better tracking solution."
          button="Access Parinity Wealthdesk"
          inverted={true}
          to="https://parinity.wealthdesk.com.au/my/home"
        />
      </Layout>
    )
  }
}
export default Comp
